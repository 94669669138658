import React from 'react'
import FinancialCharts from '../../components/graphs/financialCharts'

interface AllocationGraphProps {
  stocks: number
  bonds: number
}

class AllocationGraph extends React.Component<AllocationGraphProps> {
  public render() {
    const { stocks, bonds } = this.props
    const chartData = {
      datasets: [
        {
          data: [bonds, stocks],
          backgroundColor: ['#D3E4F3', '#2D51A2']
        }
      ]
    }

    const chartOptions = {
      animateRotate: false,
      showTooltips: false,
      responsive: false,
      cutoutPercentage: 70,
      segmentShowStroke: true,
      tooltips: {
        enabled: false
      }
    }
    return (
      <div className='ivf-allocation-description__pie-chart'>
        <FinancialCharts
          canvasId='ivf-allocation-pie-chart'
          chartData={chartData}
          chartOptions={chartOptions}
          dimensions={{ width: 110, height: 110 }}
        />
        <div className='ivf-allocation-description__pie-chart-value'>
          {stocks}/{bonds}
        </div>
      </div>
    )
  }
}

export default AllocationGraph
