import React from 'react'
import cls from 'classnames'

export const InnerContainer = (props: any) => {
  const { wide } = props
  const containerCls = cls('inner-container', {
    'inner-container--wide': Boolean(wide)
  })

  return <div className={containerCls}>{props.children}</div>
}

export default InnerContainer
